import '../css/Style.css'
import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import BANKSEA from '../image/BANKSEA_logo.jpg'
import QAimg from '../image/QAimg.png'

export default function QandA ({ PageDisplay, setPageDisplay, PageDisplaySp, setPageDisplaySp }) {

    useState(() => {
        setPageDisplay(PageDisplay);
        setPageDisplaySp(PageDisplaySp);
        const targetElement = document.getElementById(PageDisplay);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    useEffect(() => {
        console.log(PageDisplay);
    })

    const [UpBar, setUpBar] = useState('UpBar');
    const [DownBar, setDownBar] = useState('DownBar');
    const [SlideMenu, setSlideMenu] = useState('SlideMenu');
    const [MenuAni, setMenuAni] = useState(false);
    const MenuClick = () => {
        setMenuAni(!MenuAni);
        console.log('うわー' + MenuAni + 'だよ！');

        if(MenuAni === true){
            setUpBar('UpBar UpBarAni1');
            setDownBar('DownBar DownBarAni1');
            setSlideMenu('SlideMenuOff SlideMenuOffAni');
        }
        if(MenuAni === false){
            setUpBar('UpBar UpBarAni2');
            setDownBar('DownBar DownBarAni2');
            setSlideMenu('SlideMenuOn SlideMenuOnAni');
        }
    }

    const [TopMenu1, setTopMenu1] = useState('MenuPos Menu2');
    const [TopMenu2, setTopMenu2] = useState('MenuPos Menu2');
    const [TopMenu3, setTopMenu3] = useState('MenuPos Menu2');
    const [TopMenu4, setTopMenu4] = useState('MenuPos Menu2');
    const [TopMenu5, setTopMenu5] = useState('MenuPos Menu2');
    const [TopMenu6, setTopMenu6] = useState('MenuPos Menu2');
    const [TopMenu7, setTopMenu7] = useState('MenuPos Menu1');

    const MenuClick1 = () => {
        setTopMenu1('MenuPos Menu1');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setTopMenu7('MenuPos Menu2');
        setPageDisplay('Page1');
        setPageDisplaySp('PageSp1');
        navigate(-1);
    }
    const MenuClick2 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu1');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setTopMenu7('MenuPos Menu2');
        setPageDisplay('Page2');
        setPageDisplaySp('PageSp2');
        navigate(-1);
    }
    const MenuClick3 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu1');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setTopMenu7('MenuPos Menu2');
        setPageDisplay('Page3');
        setPageDisplaySp('PageSp3');
        navigate(-1);
    }
    const MenuClick4 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu1');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setTopMenu7('MenuPos Menu2');
        setPageDisplay('Page4');
        setPageDisplaySp('PageSp4');
        navigate(-1);
    }
    const MenuClick5 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu1');
        setTopMenu6('MenuPos Menu2');
        setTopMenu7('MenuPos Menu2');
        setPageDisplay('Page5');
        setPageDisplaySp('PageSp5');
        navigate(-1);
    }
    const MenuClick6 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu1');
        setTopMenu7('MenuPos Menu2');
        setPageDisplay('Page6');
        setPageDisplaySp('PageSp6');
        navigate(-1);
    }
    const MenuClick7 = () => {
        setTopMenu1('MenuPos Menu2');
        setTopMenu2('MenuPos Menu2');
        setTopMenu3('MenuPos Menu2');
        setTopMenu4('MenuPos Menu2');
        setTopMenu5('MenuPos Menu2');
        setTopMenu6('MenuPos Menu2');
        setTopMenu7('MenuPos Menu1');
        setPageDisplay('Page7');
        setPageDisplaySp('PageSp7');
    }

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const [QandA1, setQandA1] = useState('QArea');
    const [QandACheck1, setQandACheck1] = useState(false);
    const [OnOff1, setOnOff1] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick1 = () => {
        setQandACheck1(!QandACheck1);
        if(QandACheck1 === true){
            setQandA1('QArea PulsA1');
            setOnOff1(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck1 === false){
            setQandA1('QArea MinA1');
            setOnOff1(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA2, setQandA2] = useState('QArea');
    const [QandACheck2, setQandACheck2] = useState(false);
    const [OnOff2, setOnOff2] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick2 = () => {
        setQandACheck2(!QandACheck2);
        if(QandACheck2 === true){
            setQandA2('QArea PulsA2');
            setOnOff2(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck2 === false){
            setQandA2('QArea MinA2');
            setOnOff2(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA3, setQandA3] = useState('QArea');
    const [QandACheck3, setQandACheck3] = useState(false);
    const [OnOff3, setOnOff3] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick3 = () => {
        setQandACheck3(!QandACheck3);
        if(QandACheck3 === true){
            setQandA3('QArea PulsA3');
            setOnOff3(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck3 === false){
            setQandA3('QArea MinA3');
            setOnOff3(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA4, setQandA4] = useState('QArea');
    const [QandACheck4, setQandACheck4] = useState(false);
    const [OnOff4, setOnOff4] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick4 = () => {
        setQandACheck4(!QandACheck4);
        if(QandACheck4 === true){
            setQandA4('QArea PulsA4');
            setOnOff4(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck4 === false){
            setQandA4('QArea MinA4');
            setOnOff4(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA5, setQandA5] = useState('QArea');
    const [QandACheck5, setQandACheck5] = useState(false);
    const [OnOff5, setOnOff5] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick5 = () => {
        setQandACheck5(!QandACheck5);
        if(QandACheck5 === true){
            setQandA5('QArea PulsA5');
            setOnOff5(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck5 === false){
            setQandA5('QArea MinA5');
            setOnOff5(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA6, setQandA6] = useState('QArea');
    const [QandACheck6, setQandACheck6] = useState(false);
    const [OnOff6, setOnOff6] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick6 = () => {
        setQandACheck6(!QandACheck6);
        if(QandACheck6 === true){
            setQandA6('QArea PulsA6');
            setOnOff6(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck6 === false){
            setQandA6('QArea MinA6');
            setOnOff6(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA7, setQandA7] = useState('QArea');
    const [QandACheck7, setQandACheck7] = useState(false);
    const [OnOff7, setOnOff7] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick7 = () => {
        setQandACheck7(!QandACheck7);
        if(QandACheck7 === true){
            setQandA7('QArea PulsA7');
            setOnOff7(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck7 === false){
            setQandA7('QArea MinA7');
            setOnOff7(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA8, setQandA8] = useState('QArea');
    const [QandACheck8, setQandACheck8] = useState(false);
    const [OnOff8, setOnOff8] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick8 = () => {
        setQandACheck8(!QandACheck8);
        if(QandACheck8 === true){
            setQandA8('QArea PulsA8');
            setOnOff8(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck8 === false){
            setQandA8('QArea MinA8');
            setOnOff8(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA9, setQandA9] = useState('QArea');
    const [QandACheck9, setQandACheck9] = useState(false);
    const [OnOff9, setOnOff9] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick9 = () => {
        setQandACheck9(!QandACheck9);
        if(QandACheck9 === true){
            setQandA9('QArea PulsA9');
            setOnOff9(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck9 === false){
            setQandA9('QArea MinA9');
            setOnOff9(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA10, setQandA10] = useState('QArea');
    const [QandACheck10, setQandACheck10] = useState(false);
    const [OnOff10, setOnOff10] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick10 = () => {
        setQandACheck10(!QandACheck10);
        if(QandACheck10 === true){
            setQandA10('QArea PulsA10');
            setOnOff10(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck10 === false){
            setQandA10('QArea MinA10');
            setOnOff10(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA11, setQandA11] = useState('QArea');
    const [QandACheck11, setQandACheck11] = useState(false);
    const [OnOff11, setOnOff11] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick11 = () => {
        setQandACheck11(!QandACheck11);
        if(QandACheck11 === true){
            setQandA11('QArea PulsA11');
            setOnOff11(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck11 === false){
            setQandA11('QArea MinA11');
            setOnOff11(<div className='QTextBtn2' >-</div>);
        }
    }

    const [QandA12, setQandA12] = useState('QArea');
    const [QandACheck12, setQandACheck12] = useState(false);
    const [OnOff12, setOnOff12] = useState(<div className='QTextBtn1' >+</div>);
    const QandAClick12 = () => {
        setQandACheck12(!QandACheck12);
        if(QandACheck12 === true){
            setQandA12('QArea PulsA12');
            setOnOff12(<div className='QTextBtn1' >+</div>);
        }
        if(QandACheck12 === false){
            setQandA12('QArea MinA12');
            setOnOff12(<div className='QTextBtn2' >-</div>);
        }
    }

    return(
        <div className='Base'>
            <div className='QandABack' id='Page1'>
                <img className='QandAQAimg' src={QAimg} alt='QAimg' />
                <div className='QandAMenuArea QandAMenuAreaQA'>
                    <p className={TopMenu1} ><HashLink smooth to='#Page1' onClick={() => {MenuClick1()}}>HOME</HashLink></p>
                    <p className={TopMenu2} ><HashLink smooth to='#Page2' onClick={() => {MenuClick2()}}>ABOUT US</HashLink></p>
                    <p className={TopMenu3} ><HashLink smooth to='#Page3' onClick={() => {MenuClick3()}}>コース紹介</HashLink></p>
                    <p className={TopMenu4} ><HashLink smooth to='#Page4' onClick={() => {MenuClick4()}}>インストラクター</HashLink></p>
                    <p className={TopMenu5} ><HashLink smooth to='#Page5' onClick={() => {MenuClick5()}}>フォトギャラリー</HashLink></p>
                    <p className={TopMenu6} ><HashLink smooth to='#Page6' onClick={() => {MenuClick6()}}>アクセス</HashLink></p>
                    <p className={TopMenu7} ><HashLink smooth to='#Page7' onClick={() => {MenuClick7()}}>よくあるご質問</HashLink></p>
                    {/* <p className='MenuPos Menu2' >お問い合わせ</p> */}
                </div>
                <div className='MenuSpArea MenuSpAreaQA'>
                    <div className='MenuBar'>
                        <div className='Atari' onClick={() => {MenuClick()}}></div>
                        <div className={UpBar}></div>
                        <div className={DownBar}></div>
                    </div>
                    <div className={SlideMenu}>
                        <p className='MenuSpPos' ><HashLink smooth to='#Page1' onClick={() => {MenuClick1()}}>　HOME</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#Page2' onClick={() => {MenuClick2()}}>　ABOUT US</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#Page3' onClick={() => {MenuClick3()}}>　コース紹介</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#Page4' onClick={() => {MenuClick4()}}>　インストラクター</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#Page5' onClick={() => {MenuClick5()}}>　フォトギャラリー</HashLink></p>
                        <p className='MenuSpPos' ><HashLink smooth to='#Page6' onClick={() => {MenuClick6()}}>　アクセス</HashLink></p>
                        <p className='MenuSpPos' ><Link to='/QandA' onClick={() => {MenuClick7()}}>　よくあるご質問</Link></p>
                    </div>
                </div>
                <img className='HQandABANKSEA QandAMenuAreaQA' src={BANKSEA} alt='BANKSEA' onClick={() => {handleGoBack()}} />
                <p className='QandATitle'>よくあるご質問</p>
                <div className='QandATitleLine'></div>
                <div className={QandA1} onClick={() => {QandAClick1()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>キャンセル料はかかりますか？</p>
                    {OnOff1}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        お客様のご都合によるキャンセルは、キャンセル料が発生致します。<br/>
                        前日・・・・・コース料金の50％<br/>
                        当日・・・・・コース料金の100％<br/>
                        ※繁忙期のキャンセルは、出来るだけ早めにご連絡頂けるようよろしくお願いします。
                    </p>
                </div>
                <div className={QandA2} onClick={() => {QandAClick2()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>送迎は行っていますか？</p>
                    {OnOff2}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        はい、送迎は石垣島の「市街地」、港から20分圏内くらいまでの範囲で送迎を行っています。
                    </p>
                </div>
                <div className={QandA3} onClick={() => {QandAClick3()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>荷物を送っても大丈夫ですか？</p>
                    {OnOff3}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        はい、大丈夫です。以下の住所へ送付してください。<br/>
                        台風などの天候により配達が遅れる場合がありますので余裕を持って送ってください。<br/>
                        〒907-0024　沖縄県石垣市新川163-3 ARASHIRO HOUSE303
                    </p>
                </div>
                <div className={QandA4} onClick={() => {QandAClick4()}}>
                    <p className='Qtext'>Q.</p><p className='QText2'>3ボートで申し込んで、当日2ボートに変更する事や、<br/>またその反対（2ボート→3ボートに変更）することは出来ますか？</p>
                    {OnOff4}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        はい、2ボードにするか、3ボートにするかは当日決めて頂いても大丈夫です。<br/>
                        体調が悪ければ無理をせず、反対に調子がいいときはたくさん潜って下さい。<br/>
                        体験ダイビングの場合も同じです。
                    </p>
                </div>
                <div className={QandA5} onClick={() => {QandAClick5()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>1人でも大丈夫ですか？</p>
                    {OnOff5}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        もちろん、大丈夫です！<br/>
                        1人で来るお客様も多いですし、楽しいスタッフが対応しますので安心してご参加ください。
                    </p>
                </div>
                <div className={QandA6} onClick={() => {QandAClick6()}}>
                    <p className='Qtext'>Q.</p><p className='QText2'>船酔いが気になります。<br/>ポイントまではどれくらいかかりますか？</p>
                    {OnOff6}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        一番遠いところで40分かかります。<br/>
                        少しでも船酔いが気になる方は、酔い止めの薬を飲んでください。<br/>
                        （船にも用意しています。1個200円）
                    </p>
                </div>
                <div className={QandA7} onClick={() => {QandAClick7()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>ファンダイビングと体験ダイビングは一緒に潜れますか？</p>
                    {OnOff7}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        はい、潜れます。まず、ファンダイビングの方も体験ダイビングの方も一緒のボートで行くので、同じポイントで潜る事になります。<br/>
                        また、ファンダイビングの方が体験ダイビングの方に合わせてくれるのであれば、一緒に潜る事も可能です。
                    </p>
                </div>
                <div className={QandA8} onClick={() => {QandAClick8()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>初心者ですが、マンタのポイントに行けますか？</p>
                    {OnOff8}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        はい、行くことができます。<br/>
                        マンタの見ることの出来るポイント（マンタシティorマンタスクランブル）は、<br/>
                        平均水深が12m前後で比較的海況も穏かなポイントなので、初心者の方も安心して潜る事ができます。<br/>
                        ※海況や天候により、マンタのポイントには行けない場合がありますので、予めご理解とご了承をお願いします。
                    </p>
                </div>
                <div className={QandA9} onClick={() => {QandAClick9()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>体験ダイビングでマンタは見れますか？</p>
                    {OnOff9}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        マンタが見れるポイントは、体験ダイビングの1ダイブ目に潜るには、少し深いポイントになりますが、1ダイブ目で浅いところで慣れてもらって、2ダイブ目でマンタのポイントで潜ることは可能です。<br/>
                        ただ、マンタのポイントに行けるかどうか、またその日の何本目に潜るかは、その日の天候や海況にもよりますので、ご理解いただきますようお願いいたします。<br/>
                        また、シュノーケルでマンタを見ることも可能です。
                    </p>
                </div>
                <div className={QandA10} onClick={() => {QandAClick10()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>セッティングが不安です。</p>
                    {OnOff10}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        セッティング不安な方は、スタッフが一緒にやっていきますので気軽にお声がけください。
                    </p>
                </div>
                <div className={QandA11} onClick={() => {QandAClick11()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>潜降（耳抜き）が不安です。</p>
                    {OnOff11}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        シーフレンズの船には、潜降ロープがついています。<br/>
                        潜降ロープを掴みながらゆっくり自分のペースで耳抜きをして潜降して行きましょう。<br/>
                        そのときガイドも一緒に潜降していくので安心してください。
                    </p>
                </div>
                <div className={QandA12} onClick={() => {QandAClick12()}}>
                    <p className='Qtext'>Q.</p><p className='QText1'>目が悪いのですが、大丈夫ですか。</p>
                    {OnOff12}
                    <div className='ATextLine'></div>
                    <p className='AText'>
                        コンタクトの方は、コンタクトの上からマスクをすることができます。<br/>
                        また、眼鏡の方は度付きマスクをいくつか用意していますので、ご自身に合うものをお選びください。
                    </p>
                </div>

                <div className='QandABottom' ></div>
                <img className='QandAQAimgBottom' src={QAimg} alt='QAimg' />
            </div>
        </div>
    )
}